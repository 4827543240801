import React from 'react';
import { Router, Route, Redirect } from 'react-router-dom';
import { LoadRoute } from './LoadRoute';
import { queryVanlo } from '../resources/query';

import { history } from '../helpers/history';
import CarrierAccounts from '../pages/CarrierAccounts';
import ResetPassword from '../pages/ResetPassword';
import Shipments from '../pages/Shipments';
import NewShipment from '../pages/NewShipment';
import BuyLabels from '../pages/BuyLabels';
import Balances from '../pages/Balances';
import ApiKeys from '../pages/Api';
import PaymentMethods from '../pages/PaymentMethods';
import BillingInformation from '../pages/BillingInformation';
import Locations from '../pages/Locations';
import Webhooks from '../pages/Webhooks';
import Permissions from '../pages/Permissions';
import WelcomeScreen from '../pages/WelcomeScreen';
import BusinessInformation from '../pages/BusinessInformation';
import Invoices from '../pages/Invoices';
import Adjustments from '../pages/Adjustments';
import Reports from '../pages/Reports';
import GenerateReport from '../pages/Reports/GenerateReport';
import ReportSchedules from '../pages/ReportSchedules';
import NewReportSchedule from '../pages/ReportSchedules/NewReportSchedule';
import EditTrackingPage from '../pages/TrackingPage/Edit';
import PackingSlipEditor from '../pages/PackingSlip/Editor';
import ScanForms from '../pages/ScanForms';
import Analytics from '../pages/Analytics';
import ShipmentUploads from '../pages/ShipmentUploads';
import ShipmentUploadMappings from '../pages/ShipmentUploadMappings';
import LeadSearches from '../pages/LeadSearches';
import ShopConnections from '../pages/ShopConnections';
import ShopOrders from '../pages/ShopOrders';
import Automations from '../pages/Automations';
import SearchPresets from '../pages/SearchPresets';
import ExternalBalances from '../pages/ExternalBalances';
import ChannelBalances from '../pages/ChannelBalances';
import {
  ShipmentResource,
  UserSettingsResource,
  BalanceResource,
  BalanceTransactionResource,
  ApiKeyResource,
  PaymentMethodResource,
  UserResource,
  AddressesResource,
  WarehouseAddressesResource,
  WebhooksResource,
  ExternalTransactionResource,
  ChannelTransactionResource,
  ExternalBalanceResource,
  ChannelBalanceResource,
  CarrierAccountsResource,
  BusinessInformationResource,
  PersonsResource,
  InvoicesResource,
  AdjustmentsResource,
  ReportsResource,
  ReportSchedulesResource,
  TrackingPageResource,
  PackingSlipDesignResource,
  ScanFormsResource,
  UserSummariesResource,
  ReadyShipmentsResource,
  ShipmentUploadMappingsResource,
  ShipmentUploadsResource,
  LeadSearchesResource,
  ShopConnectionsResource,
  ShopOrdersResource,
  AutomationsResource,
  SearchPresetsResource,
} from '../resources';

const token = window.location.search.replace('?token=', '');

export const BodyContainer = ({ activeRoute }) => {
  return (
    <div className="v-body">
      <Router history={history}>
        {(!token || !token.length) && (
          <Route exact path="/">
            <Redirect to="/shipments" />
          </Route>
        )}
        <LoadRoute
          exact
          activeRoute={activeRoute}
          path="/shipments"
          resources={[ShipmentResource, UserSettingsResource]}
          component={Shipments}
        />
        <LoadRoute
          exact
          activeRoute={activeRoute}
          path="/shipments/buy-labels"
          resources={[ReadyShipmentsResource]}
          component={BuyLabels}
        />
        <LoadRoute
          exact
          activeRoute={activeRoute}
          path="/shop-orders"
          resources={[ShopOrdersResource]}
          component={ShopOrders}
        />
        <LoadRoute
          exact
          activeRoute={activeRoute}
          path="/invoices"
          resources={[
            InvoicesResource,
            BalanceResource,
            PaymentMethodResource,
            UserSettingsResource,
          ]}
          component={Invoices}
        />
        <LoadRoute
          exact
          activeRoute={activeRoute}
          path="/adjustments"
          resources={[AdjustmentsResource]}
          component={Adjustments}
        />
        <LoadRoute
          activeRoute={activeRoute}
          path="/shipment/:id"
          resources={[]}
          component={NewShipment}
        />
        <LoadRoute
          exact
          activeRoute={activeRoute}
          path="/reports/generate"
          resources={[ReportsResource]}
          component={GenerateReport}
        />
        <LoadRoute
          exact
          activeRoute={activeRoute}
          path="/reports"
          resources={[ReportsResource]}
          component={Reports}
        />
        <LoadRoute
          exact
          activeRoute={activeRoute}
          path="/scheduled-reports/new"
          resources={[ReportSchedulesResource, UserResource]}
          component={NewReportSchedule}
        />
        <LoadRoute
          exact
          activeRoute={activeRoute}
          path="/scheduled-reports"
          resources={[ReportSchedulesResource]}
          component={ReportSchedules}
        />
        <LoadRoute
          exact
          activeRoute={activeRoute}
          path="/tracking-page"
          resources={[UserResource, TrackingPageResource]}
          component={EditTrackingPage}
        />
        <LoadRoute
          exact
          activeRoute={activeRoute}
          path="/packing-slip"
          resources={[PackingSlipDesignResource]}
          component={PackingSlipEditor}
        />
        <LoadRoute
          exact
          activeRoute={activeRoute}
          path="/balance"
          resources={[BalanceResource, BalanceTransactionResource, UserSettingsResource]}
          component={Balances}
        />
        <LoadRoute
          exact
          activeRoute={activeRoute}
          path="/external-billing"
          resources={[
            ExternalTransactionResource,
            ExternalBalanceResource,
            UserSettingsResource,
            PaymentMethodResource,
          ]}
          component={ExternalBalances}
        />
        <LoadRoute
          exact
          activeRoute={activeRoute}
          path="/channel-billing"
          resources={[ChannelTransactionResource, ChannelBalanceResource, UserSettingsResource, PaymentMethodResource]}
          component={ChannelBalances}
        />
        <LoadRoute
          exact
          activeRoute={activeRoute}
          path="/payment-methods"
          resources={[PaymentMethodResource, UserResource, UserSettingsResource, BalanceResource]}
          component={PaymentMethods}
        />
        <LoadRoute
          exact
          activeRoute={activeRoute}
          path="/billing-information"
          resources={[UserResource, AddressesResource]}
          component={BillingInformation}
        />
        <LoadRoute
          exact
          activeRoute={activeRoute}
          path="/webhooks"
          resources={[WebhooksResource]}
          component={Webhooks}
        />
        <LoadRoute
          exact
          activeRoute={activeRoute}
          path="/apikey"
          resources={[ApiKeyResource]}
          component={ApiKeys}
        />
        <LoadRoute
          exact
          activeRoute={activeRoute}
          path="/reset-password"
          component={ResetPassword}
        />
        <LoadRoute
          exact
          activeRoute={activeRoute}
          path="/carrier-accounts"
          resources={[CarrierAccountsResource]}
          component={CarrierAccounts}
        />
        <LoadRoute
          exact
          activeRoute={activeRoute}
          path="/shop-connections"
          resources={[ShopConnectionsResource]}
          component={ShopConnections}
        />
        <LoadRoute
          exact
          activeRoute={activeRoute}
          path="/permissions"
          resources={[PersonsResource]}
          component={Permissions}
        />
        <LoadRoute
          exact
          activeRoute={activeRoute}
          path="/locations"
          resources={[WarehouseAddressesResource]}
          component={Locations}
        />
        <LoadRoute
          exact
          activeRoute={activeRoute}
          path="/business-information"
          resources={[BusinessInformationResource]}
          component={BusinessInformation}
        />
        <LoadRoute
          exact
          activeRoute={activeRoute}
          path="/welcome"
          resources={[UserResource]}
          component={WelcomeScreen}
        />
        <LoadRoute
          exact
          activeRoute={activeRoute}
          path="/scan-forms"
          resources={[ScanFormsResource]}
          component={ScanForms}
        />
        <LoadRoute
          exact
          activeRoute={activeRoute}
          path="/analytics"
          resources={[UserSummariesResource]}
          component={Analytics}
        />
        <LoadRoute
          exact
          activeRoute={activeRoute}
          path="/shipment-uploads"
          resources={[ShipmentUploadsResource, ShipmentUploadMappingsResource]}
          component={ShipmentUploads}
        />
        <LoadRoute
          exact
          activeRoute={activeRoute}
          path="/shipment-upload-mappings"
          resources={[ShipmentUploadMappingsResource]}
          component={ShipmentUploadMappings}
        />
        <LoadRoute
          exact
          activeRoute={activeRoute}
          path="/lead-searches"
          resources={[LeadSearchesResource]}
          component={LeadSearches}
        />
        <LoadRoute
          exact
          activeRoute={activeRoute}
          path="/automations"
          resources={[AutomationsResource]}
          component={Automations}
        />
        <LoadRoute
          exact
          activeRoute={activeRoute}
          path="/search-presets"
          resources={[SearchPresetsResource]}
          component={SearchPresets}
        />
        <Route
          exact
          path="/auth/finalize"
          render={() => {
            const searchParams = new URLSearchParams(window.location.search);
            queryVanlo(`/auth/finalize/${searchParams.get('key')}`, 'POST').then(() => {
              window.location.href = '/shop-connections';
            });
          }}
        />
      </Router>
    </div>
  );
};

import { resource } from './resource';
import { queryVanlo } from './query';

const route      = '/channel_balance/transactions';
const storageKey = 'SET_CHANNEL_TRANSACTIONS';
const filters    = {
  q:    null,
  per:  50,
  page: 1,
};
function exportCSV(email) {
  const { page, per, ...rest } = this.filters;
  return queryVanlo(`/dashboard/channel_balance/report`, 'POST', {
    ...rest,
    email,
  });
}

function approve(id) {
  return queryVanlo(`/dashboard/incentive_reports/${id}/approve`, 'PATCH', {});
}
export const ChannelTransactionResource = resource({
  route,
  storageKey,
  filters,
  exportCSV,
  approve,
});

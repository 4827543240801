const initialState = {
  balance: {
    channel_value: '$0.00',
  },
  account:      {},
  transactions: [],
  options:      { kinds: [] },
};

export const channelBalance = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case 'SET_CHANNEL_BALANCE': {
      return { ...state, ...payload };
    }
    default:
      return state;
  }
};
